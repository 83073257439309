export const customerOrdersQuery = (isBackward) => `
query customerQuery($input: String!, $numProducts: Int!, $cursor: String) {
  customer(customerAccessToken: $input) {
    numberOfOrders
    orders (
    ${isBackward ? 'last' : 'first'}: $numProducts, 
    ${isBackward ? 'before' : 'after'}: $cursor
    ) {
        edges {
            node {
                id
                orderNumber
                fulfillmentStatus
                financialStatus
                totalPrice {
                    amount
                }
                processedAt
            }
            cursor
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
        }    
    }
  }
}
    `
