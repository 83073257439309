export const customerAddressDeleteMutation = `
mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
  customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
    customerUserErrors {
      code
      field
      message
    }
    deletedCustomerAddressId
    userErrors {
      field
      message
    }
  }
}
    `
