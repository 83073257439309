export const predictiveSearchQuery = `
query suggestions($query: String!) {
  predictiveSearch(query: $query) {
    products {
          id
          handle
          title
          description
          tags
          totalInventory
          rentalMetafields: metafields(identifiers: [{key: "accessories", namespace: "rental"},
          {key: "training_title", namespace: "rental"}, 
          {key: "training_text", namespace: "rental"}, 
          {key: "rrp", namespace: "rental"}, 
          {key: "json_config", namespace: "rental"}, 
          {key: "link", namespace: "rental"}]){
            value
          }
          productType
          priceRange {
            maxVariantPrice {
              amount
            }
          }
          featuredImage {
            url
            height
            width
            altText
          }
    }
  }
}
`
