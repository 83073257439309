export const createGatsbyImageData = (imageData) => ({
  images: {
    fallback: {
      src: imageData.fields.file.url,
      srcSet: `${imageData.fields.file.url} 1x`,
      sizes: '(max-width: 800px) 100vw, 800px',
    },
  },
  layout: 'constrained',
  width: imageData.fields.file.details?.image?.width || 1600,
  height: imageData.fields.file.details?.image?.height || 1600,
  placeholder: {
    fallback: imageData.fields.file.url,
  },
})

export const createGatsbyImageDataPlaceholder = ({ width, height, src } = {}) => ({
  images: {
    fallback: {
      src: src || 'https://placehold.co/1600x1600',
      srcSet: `${src || 'https://placehold.co/1600x1600'} 1x`,
      sizes: '(max-width: 800px) 100vw, 800px',
    },
  },
  layout: 'constrained',
  width: width || 1600,
  height: height || 1600,
  placeholder: {
    fallback: src || 'https://placehold.co/1600x1600',
  },
})
