import React from 'react'
import styled, { keyframes } from 'styled-components'
import { brandOrange } from '../../../assets/consts/color'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const LoaderStyled = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid ${brandOrange};
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  animation: ${spin} 2s linear infinite;
`

function Loader({ size }) {
  return <LoaderStyled width={size} height={size} />
}

export default Loader
