export const customerAccessTokenCreateMutation = `
mutation customerAccessTokenCreate ($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
    }
    customerUserErrors {
      message
    }
  }
}
    `
