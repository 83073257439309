import React, { createContext, useState, useContext, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Toast from '../components/organisms/Toast/toast'

const defaultValues = {
  showToast: () => {},
}

const ToastContext = createContext(defaultValues)

export const useToast = () => useContext(ToastContext)

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([])

  const showToast = (title, body) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      {
        title,
        body,
        id: Date.now(),
      },
    ])
  }

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <div className="toast-container">
        {toasts.map((toast) => (
          <ToastWithTimer
            key={toast.id}
            header={toast.title}
            body={toast.body}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-200px);
  }
`

const ToastWrapper = styled.div`
  position: fixed;
  top: 100px;
  right: 20px;
  min-width: 200px;
  max-width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation:
    ${fadeIn} 0.5s ease-in-out,
    ${fadeOut} 0.5s ease-in-out 5s forwards;
`

function ToastWithTimer({ onClose, ...prop }) {
  useEffect(() => {
    const timer = setTimeout(onClose, 6000) // 5s display + 0.5s fade out
    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <ToastWrapper>
      <Toast {...prop} />
    </ToastWrapper>
  )
}
