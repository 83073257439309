export const formatPhoneNumber = (str) => {
  if (typeof str !== 'string' && str.length !== 11) return ''

  const formatedPhone = str
    .split('')
    .reduce((arr, item, i) => {
      if (i === 3 || i === 6) return [...arr, item, '']

      return [...arr, item]
    }, [])
    .join('')

  return formatedPhone
}
