export const predictiveSearchTitlesQuery = `
query suggestions($query: String!) {
  predictiveSearch(query: $query) {
    products {
          id
          title
          handle
          tags
          featuredImage {
            url
          }
    }
  }
}
`
