import React from 'react'
import CombinedProvider from '../context/CombinedProvider'
import appleFavIcon from '../../apple-touch-icon.png'
import androidChromeFaveIcon32x32 from '../../favicon-32x32.png'
import androidChromeFaveIcon16x16 from '../../favicon-16x16.png'

// Gatsby Head
//---------------------------------
export function Head({ data }) {
  return (
    <>
      <html lang="en" />
      <meta charset="UTF-8" />
      <title>{data.allContentfulPageHomepage.edges[0].node.pageMetaTitle}</title>
      <meta
        name="description"
        content={data.allContentfulPageHomepage.edges[0].node.pageMetaDescription}
      />
      <meta name="keywords" content="" />
      <meta name="author" content="Heliguy" />
      <link rel="apple-touch-icon" sizes="180x180" href={appleFavIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={androidChromeFaveIcon32x32} />
      <link rel="icon" type="image/png" sizes="16x16" href={androidChromeFaveIcon16x16} />
      <link rel="manifest" href="../../site.webmanifest" />
    </>
  )
}

function RootElement({ children }) {
  return (
    <>
      <CombinedProvider>{children}</CombinedProvider>
      <div key="modal-root" id="modal-root" />
    </>
  )
}

export default RootElement
