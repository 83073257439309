require('./src/assets/css/main.scss')
const React = require('react')
const ReactDOM = require('react-dom/client')
const { default: SkeletonElement } = require('./src/components/organisms/Skeleton/Skeleton')
const { AppWrappedProvider } = require('./src/context/AppWrappedContext')

const DefaultLayout = require('./src/components/pages/Layout').default
const GlobalNavigation = require('./src/components/organisms/NewNavigation/NewNavigation').default
const GlobalFooter = require('./src/components/organisms/GlobalFooter/GlobalFooter').default
const RootElement = require('./src/components/root-element').default

const GoogleRatingBadge = React.lazy(
  () => import('./src/components/organisms/GoogleRatingBadge/GoogleRatingBadge')
)
const CookieBanner = React.lazy(
  () => import('./src/components/organisms/CookieBanner/CookieBanner')
)

exports.wrapPageElement = ({ element, props }) => (
  <AppWrappedProvider>
    <GlobalNavigation pageContext={props.pageContext} />
    <DefaultLayout>{element}</DefaultLayout>
    <React.Suspense fallback={<SkeletonElement placeholder="Loading cookie block..." />}>
      <CookieBanner />
    </React.Suspense>
    <React.Suspense fallback={<SkeletonElement placeholder="Loading google rating block..." />}>
      <GoogleRatingBadge />
    </React.Suspense>
    <GlobalFooter />
  </AppWrappedProvider>
)

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

exports.wrapRootElement = ({ element }) => <RootElement>{element}</RootElement>

exports.onInitialClientRender = () => {
  // Move Klaviyo script here to ensure it loads after initial render
  const scriptKlaviyo = document.createElement('script')
  scriptKlaviyo.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=U7wVZm'
  scriptKlaviyo.async = true
  document.body.appendChild(scriptKlaviyo)

  // Move Google badge script here to ensure it loads after initial render
  const scriptGoogleBadge = document.createElement('script')
  scriptGoogleBadge.src = 'https://apis.google.com/js/platform.js?onload=renderBadge'
  scriptGoogleBadge.async = true
  scriptGoogleBadge.defer = true
  document.body.appendChild(scriptGoogleBadge)
}

exports.onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)
}
