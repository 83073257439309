import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { customerClient } from '../utils/customer/customer'
import { navigate } from 'gatsby'
import { ROUTES } from '../constants/routes'

const defaultValues = {
  accessToken: null,
  customer: {},
  loading: false,
  getToken: () => {},
  saveToken: () => {},
  removeToken: () => {},
  getCustomer: () => {},
  logout: () => {},
}

const CustomerContext = createContext(defaultValues)

const isBrowser = typeof window !== 'undefined'
const localStorageTokenKey = 'shopify_customer_token'

export function CustomerProvider({ children }) {
  const [accessToken, setAccessToken] = useState(defaultValues.accessToken)
  const [customer, setCustomer] = useState(defaultValues.customer)
  const [loading, setLoading] = useState(defaultValues.loading)

  const getToken = useCallback(() => {
    if (!isBrowser) return null
    return localStorage.getItem(localStorageTokenKey)
  }, [])

  const saveToken = useCallback((token) => {
    if (!isBrowser) return
    localStorage.setItem(localStorageTokenKey, token)
    setAccessToken(token)
  }, [])

  const removeToken = useCallback(() => {
    if (!isBrowser) return
    localStorage.removeItem(localStorageTokenKey)
    setAccessToken(null)
  }, [])

  const getCustomer = useCallback(async () => {
    const storageToken = getToken()
    const token = accessToken || storageToken

    if (!token) return {}

    try {
      setLoading(true)
      const customerData = await customerClient.getCustomer(token)
      setAccessToken(token)
      setCustomer(customerData)
    } catch (e) {
      console.error('Error getting customer', e)
    } finally {
      setLoading(false)
    }
  }, [getToken, accessToken])

  useEffect(() => {
    if (!isBrowser) return

    getCustomer()
  }, [getCustomer])

  const logout = useCallback(() => {
    setCustomer(defaultValues.customer)
    setAccessToken(defaultValues.accessToken)
    removeToken()
    navigate(ROUTES.LOGIN)
  }, [removeToken])

  return (
    <CustomerContext.Provider
      value={{
        ...defaultValues,
        loading,
        customer,
        setCustomer,
        getCustomer,
        saveToken,
        removeToken,
        getToken,
        accessToken,
        logout,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

const useCustomer = () => {
  const context = useContext(CustomerContext)

  if (context === undefined) {
    throw new Error('useCustomer must be used within CustomerContext')
  }

  return context
}

export default useCustomer
