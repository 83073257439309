// Module Import
//---------------------------------
import React from 'react'
import styled from 'styled-components'
import TypographyAtom from '../../atoms/typography/typography'
import ImageCut from '../../../assets/images/graphic_imageCut.svg'
import { brandIvory } from '../../../assets/consts/color'

// Component Import
//---------------------------------

// Styled Component Declarations
//---------------------------------
const ToastWrapper = styled.div`
  background: ${brandIvory};
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    background-image: url(${ImageCut});
    background-position: top left;
    background-size: cover;
    content: '';
    height: 30px;
    left: -1px;
    position: absolute;
    top: -1px;
    transform: rotate(180deg);
    width: 30px;
    z-index: 1;
  }
`
// Component Export
//---------------------------------
export default function Toast({ header, body }) {
  return (
    <ToastWrapper>
      <TypographyAtom type="H5" copy={header} weight="Bold" />
      <TypographyAtom type="P" copy={body} weight="Regular" />
    </ToastWrapper>
  )
}

Toast.propTypes = {}
