// Module Import
//---------------------------------
import React from 'react'
import { Link } from 'gatsby-link'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// Style Tokens
//---------------------------------
import tokenData from '../../../assets/design_tokens/heliguy-2024-designTokens-transformed.json'
import {
  brandBlack,
  brandDarkGrey,
  brandIvory,
  brandKhaki,
  brandOrange,
  brandSoftOrange,
  brandWhite,
} from '../../../assets/consts/color'
import { primaryFont, secondaryFont } from '../../../assets/consts/fonts'

// Global Nav Link Top Level
// Inactive
// Box Model
const GlobalNav_link_TopLevel_boxModel_background_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.fill
const GlobalNav_link_TopLevel_boxModel_paddingHorizontal_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.horizontalPadding
const GlobalNav_link_TopLevel_boxModel_paddingVertical_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.verticalPadding
const GlobalNav_link_TopLevel_boxModel_borderRadius_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.borderRadius
const GlobalNav_link_TopLevel_boxModel_borderWidth_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.borderWidth
const GlobalNav_link_TopLevel_boxModel_borderColor_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.borderColor
const GlobalNav_link_TopLevel_boxModel_boxShadow_inactive = `${tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.TopLevel.Inactive.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_TopLevel_copy_fontColour_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.copy.value.fill
const GlobalNav_link_TopLevel_copy_fontFamily_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.copy.value.typography.fontFamily
const GlobalNav_link_TopLevel_copy_fontSize_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.copy.value.typography.fontSize
const GlobalNav_link_TopLevel_copy_fontWeight_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.copy.value.typography.fontWeight
const GlobalNav_link_TopLevel_copy_letterSpacing_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.copy.value.typography.letterSpacing
const GlobalNav_link_TopLevel_copy_lineHeight_inactive =
  tokenData.Link.GlobalNavigation.TopLevel.Inactive.copy.value.typography.lineHeight

// Hover
// Box Model
const GlobalNav_link_TopLevel_boxModel_background_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.fill
const GlobalNav_link_TopLevel_boxModel_paddingHorizontal_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.horizontalPadding
const GlobalNav_link_TopLevel_boxModel_paddingVertical_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.verticalPadding
const GlobalNav_link_TopLevel_boxModel_borderRadius_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.borderRadius
const GlobalNav_link_TopLevel_boxModel_borderWidth_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.borderWidth
const GlobalNav_link_TopLevel_boxModel_borderColor_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.borderColor
const GlobalNav_link_TopLevel_boxModel_boxShadow_hover = `${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_TopLevel_copy_fontColour_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.fill
const GlobalNav_link_TopLevel_copy_fontFamily_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.fontFamily
const GlobalNav_link_TopLevel_copy_fontSize_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.fontSize
const GlobalNav_link_TopLevel_copy_fontWeight_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.fontWeight
const GlobalNav_link_TopLevel_copy_letterSpacing_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.letterSpacing
const GlobalNav_link_TopLevel_copy_lineHeight_hover =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.lineHeight

// Active
// Box Model
const GlobalNav_link_TopLevel_boxModel_background_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.fill
const GlobalNav_link_TopLevel_boxModel_paddingHorizontal_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.horizontalPadding
const GlobalNav_link_TopLevel_boxModel_paddingVertical_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.verticalPadding
const GlobalNav_link_TopLevel_boxModel_borderRadius_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.borderRadius
const GlobalNav_link_TopLevel_boxModel_borderWidth_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.borderWidth
const GlobalNav_link_TopLevel_boxModel_borderColor_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.borderColor
const GlobalNav_link_TopLevel_boxModel_boxShadow_active = `${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.TopLevel.Hover.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_TopLevel_copy_fontColour_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.fill
const GlobalNav_link_TopLevel_copy_fontFamily_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.fontFamily
const GlobalNav_link_TopLevel_copy_fontSize_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.fontSize
const GlobalNav_link_TopLevel_copy_fontWeight_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.fontWeight
const GlobalNav_link_TopLevel_copy_letterSpacing_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.letterSpacing
const GlobalNav_link_TopLevel_copy_lineHeight_active =
  tokenData.Link.GlobalNavigation.TopLevel.Hover.copy.value.typography.lineHeight

// Global Nav Link Top Level
// Inactive
// Box Model
const GlobalNav_link_TopLevelAlt_boxModel_background_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.fill
const GlobalNav_link_TopLevelAlt_boxModel_paddingHorizontal_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.horizontalPadding
const GlobalNav_link_TopLevelAlt_boxModel_paddingVertical_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.verticalPadding
const GlobalNav_link_TopLevelAlt_boxModel_borderRadius_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.borderRadius
const GlobalNav_link_TopLevelAlt_boxModel_borderWidth_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.borderWidth
const GlobalNav_link_TopLevelAlt_boxModel_borderColor_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.borderColor
const GlobalNav_link_TopLevelAlt_boxModel_boxShadow_inactive = `${tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_TopLevelAlt_copy_fontColour_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.copy.value.fill
const GlobalNav_link_TopLevelAlt_copy_fontFamily_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.copy.value.typography.fontFamily
const GlobalNav_link_TopLevelAlt_copy_fontSize_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.copy.value.typography.fontSize
const GlobalNav_link_TopLevelAlt_copy_fontWeight_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.copy.value.typography.fontWeight
const GlobalNav_link_TopLevelAlt_copy_letterSpacing_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.copy.value.typography.letterSpacing
const GlobalNav_link_TopLevelAlt_copy_lineHeight_inactive =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Inactive.copy.value.typography.lineHeight

// Hover
// Box Model
const GlobalNav_link_TopLevelAlt_boxModel_background_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.fill
const GlobalNav_link_TopLevelAlt_boxModel_paddingHorizontal_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.horizontalPadding
const GlobalNav_link_TopLevelAlt_boxModel_paddingVertical_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.verticalPadding
const GlobalNav_link_TopLevelAlt_boxModel_borderRadius_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.borderRadius
const GlobalNav_link_TopLevelAlt_boxModel_borderWidth_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.borderWidth
const GlobalNav_link_TopLevelAlt_boxModel_borderColor_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.borderColor
const GlobalNav_link_TopLevelAlt_boxModel_boxShadow_hover = `${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_TopLevelAlt_copy_fontColour_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.fill
const GlobalNav_link_TopLevelAlt_copy_fontFamily_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.fontFamily
const GlobalNav_link_TopLevelAlt_copy_fontSize_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.fontSize
const GlobalNav_link_TopLevelAlt_copy_fontWeight_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.fontWeight
const GlobalNav_link_TopLevelAlt_copy_letterSpacing_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.letterSpacing
const GlobalNav_link_TopLevelAlt_copy_lineHeight_hover =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.lineHeight

// Active
// Box Model
const GlobalNav_link_TopLevelAlt_boxModel_background_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.fill
const GlobalNav_link_TopLevelAlt_boxModel_paddingHorizontal_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.horizontalPadding
const GlobalNav_link_TopLevelAlt_boxModel_paddingVertical_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.verticalPadding
const GlobalNav_link_TopLevelAlt_boxModel_borderRadius_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.borderRadius
const GlobalNav_link_TopLevelAlt_boxModel_borderWidth_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.borderWidth
const GlobalNav_link_TopLevelAlt_boxModel_borderColor_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.borderColor
const GlobalNav_link_TopLevelAlt_boxModel_boxShadow_active = `${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_TopLevelAlt_copy_fontColour_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.fill
const GlobalNav_link_TopLevelAlt_copy_fontFamily_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.fontFamily
const GlobalNav_link_TopLevelAlt_copy_fontSize_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.fontSize
const GlobalNav_link_TopLevelAlt_copy_fontWeight_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.fontWeight
const GlobalNav_link_TopLevelAlt_copy_letterSpacing_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.letterSpacing
const GlobalNav_link_TopLevelAlt_copy_lineHeight_active =
  tokenData.Link.GlobalNavigation.TopLevelAlt.Hover.copy.value.typography.lineHeight

// Global Nav Link Sub Level
// Inactive
// Box Model
const GlobalNav_link_SubLevel_boxModel_background_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.fill
const GlobalNav_link_SubLevel_boxModel_paddingHorizontal_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.horizontalPadding
const GlobalNav_link_SubLevel_boxModel_paddingVertical_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.verticalPadding
const GlobalNav_link_SubLevel_boxModel_borderRadius_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.borderRadius
const GlobalNav_link_SubLevel_boxModel_borderWidth_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.borderWidth
const GlobalNav_link_SubLevel_boxModel_borderColor_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.borderColor
const GlobalNav_link_SubLevel_boxModel_boxShadow_inactive = `${tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.SubLevel.Inactive.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_SubLevel_copy_fontColour_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.copy.value.fill
const GlobalNav_link_SubLevel_copy_fontFamily_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.copy.value.typography.fontFamily
const GlobalNav_link_SubLevel_copy_fontSize_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.copy.value.typography.fontSize
const GlobalNav_link_SubLevel_copy_fontWeight_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.copy.value.typography.fontWeight
const GlobalNav_link_SubLevel_copy_letterSpacing_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.copy.value.typography.letterSpacing
const GlobalNav_link_SubLevel_copy_lineHeight_inactive =
  tokenData.Link.GlobalNavigation.SubLevel.Inactive.copy.value.typography.lineHeight

// Hover
// Box Model
const GlobalNav_link_SubLevel_boxModel_background_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.fill
const GlobalNav_link_SubLevel_boxModel_paddingHorizontal_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.horizontalPadding
const GlobalNav_link_SubLevel_boxModel_paddingVertical_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.verticalPadding
const GlobalNav_link_SubLevel_boxModel_borderRadius_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.borderRadius
const GlobalNav_link_SubLevel_boxModel_borderWidth_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.borderWidth
const GlobalNav_link_SubLevel_boxModel_borderColor_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.borderColor
const GlobalNav_link_SubLevel_boxModel_boxShadow_hover = `${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_SubLevel_copy_fontColour_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.fill
const GlobalNav_link_SubLevel_copy_fontFamily_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.fontFamily
const GlobalNav_link_SubLevel_copy_fontSize_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.fontSize
const GlobalNav_link_SubLevel_copy_fontWeight_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.fontWeight
const GlobalNav_link_SubLevel_copy_letterSpacing_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.letterSpacing
const GlobalNav_link_SubLevel_copy_lineHeight_hover =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.lineHeight

// Active
// Box Model
const GlobalNav_link_SubLevel_boxModel_background_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.fill
const GlobalNav_link_SubLevel_boxModel_paddingHorizontal_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.horizontalPadding
const GlobalNav_link_SubLevel_boxModel_paddingVertical_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.verticalPadding
const GlobalNav_link_SubLevel_boxModel_borderRadius_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.borderRadius
const GlobalNav_link_SubLevel_boxModel_borderWidth_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.borderWidth
const GlobalNav_link_SubLevel_boxModel_borderColor_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.borderColor
const GlobalNav_link_SubLevel_boxModel_boxShadow_active = `${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.x}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.y}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.blur}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.spread}px ${tokenData.Link.GlobalNavigation.SubLevel.Hover.container.value.boxShadow.color}`

// Typography
const GlobalNav_link_SubLevel_copy_fontColour_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.fill
const GlobalNav_link_SubLevel_copy_fontFamily_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.fontFamily
const GlobalNav_link_SubLevel_copy_fontSize_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.fontSize
const GlobalNav_link_SubLevel_copy_fontWeight_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.fontWeight
const GlobalNav_link_SubLevel_copy_letterSpacing_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.letterSpacing
const GlobalNav_link_SubLevel_copy_lineHeight_active =
  tokenData.Link.GlobalNavigation.SubLevel.Hover.copy.value.typography.lineHeight

// Styled Component Declarations
//---------------------------------
const LinkGlobalNavTop = styled.div`
  display: flex;
  flex: 1;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 0.25s;
    background-color: ${GlobalNav_link_TopLevel_boxModel_background_inactive};
    /* padding:${GlobalNav_link_TopLevel_boxModel_paddingVertical_inactive}px ${GlobalNav_link_TopLevel_boxModel_paddingHorizontal_inactive}px; */
    padding: 32px;
    border-radius: ${GlobalNav_link_TopLevel_boxModel_borderRadius_inactive}px;
    border-width: ${GlobalNav_link_TopLevel_boxModel_borderWidth_inactive}px;
    border-color: ${GlobalNav_link_TopLevel_boxModel_borderColor_inactive};
    box-shadow: ${GlobalNav_link_TopLevel_boxModel_boxShadow_inactive};
    font-family: ${GlobalNav_link_TopLevel_copy_fontFamily_inactive};
    /* font-size: ${GlobalNav_link_TopLevel_copy_fontSize_inactive}px; */
    font-size: 14px;
    font-weight: ${GlobalNav_link_TopLevel_copy_fontWeight_inactive};
    color: ${GlobalNav_link_TopLevel_copy_fontColour_inactive};
    letter-spacing: ${GlobalNav_link_TopLevel_copy_letterSpacing_inactive}px;
    line-height: ${GlobalNav_link_TopLevel_copy_lineHeight_inactive};

    &:hover {
      background-color: ${GlobalNav_link_TopLevel_boxModel_background_hover};
      /* padding: ${GlobalNav_link_TopLevel_boxModel_paddingVertical_hover}px ${GlobalNav_link_TopLevel_boxModel_paddingHorizontal_hover}px; */
      padding: 32px;
      border-radius: ${GlobalNav_link_TopLevel_boxModel_borderRadius_hover}px;
      border-width: ${GlobalNav_link_TopLevel_boxModel_borderWidth_hover}px;
      border-color: ${GlobalNav_link_TopLevel_boxModel_borderColor_hover};
      box-shadow: ${GlobalNav_link_TopLevel_boxModel_boxShadow_hover};
      font-family: ${GlobalNav_link_TopLevel_copy_fontFamily_hover};
      /* font-size: ${GlobalNav_link_TopLevel_copy_fontSize_hover}px; */
      font-size: 14px;
      font-weight: ${GlobalNav_link_TopLevel_copy_fontWeight_hover};
      color: ${GlobalNav_link_TopLevel_copy_fontColour_hover};
      letter-spacing: ${GlobalNav_link_TopLevel_copy_letterSpacing_hover}px;
      line-height: ${GlobalNav_link_TopLevel_copy_lineHeight_hover};
    }

    &:focus {
    }

    &:active {
      text-decoration: none;
      background-color: ${GlobalNav_link_TopLevel_boxModel_background_active};
      /* padding: ${GlobalNav_link_TopLevel_boxModel_paddingVertical_active}px ${GlobalNav_link_TopLevel_boxModel_paddingHorizontal_active}px; */
      padding: 32px;
      border-radius: ${GlobalNav_link_TopLevel_boxModel_borderRadius_active}px;
      border-width: ${GlobalNav_link_TopLevel_boxModel_borderWidth_active}px;
      border-color: ${GlobalNav_link_TopLevel_boxModel_borderColor_active};
      box-shadow: ${GlobalNav_link_TopLevel_boxModel_boxShadow_active};
      font-family: ${GlobalNav_link_TopLevel_copy_fontFamily_active};
      /* font-size: ${GlobalNav_link_TopLevel_copy_fontSize_active}px; */
      font-size: 14px;
      font-weight: ${GlobalNav_link_TopLevel_copy_fontWeight_active};
      color: ${GlobalNav_link_TopLevel_copy_fontColour_active};
      letter-spacing: ${GlobalNav_link_TopLevel_copy_letterSpacing_active}px;
      line-height: ${GlobalNav_link_TopLevel_copy_lineHeight_active};
    }

    &:disabled {
    }
  }
`

const LinkGlobalNavTopAlt = styled.div`
  display: flex;
  flex: 1;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 0.25s;
    background-color: ${GlobalNav_link_TopLevel_boxModel_background_inactive};
    /* padding:${GlobalNav_link_TopLevel_boxModel_paddingVertical_inactive}px ${GlobalNav_link_TopLevel_boxModel_paddingHorizontal_inactive}px; */
    padding: 32px;
    border-radius: ${GlobalNav_link_TopLevel_boxModel_borderRadius_inactive}px;
    border-width: ${GlobalNav_link_TopLevel_boxModel_borderWidth_inactive}px;
    border-color: ${GlobalNav_link_TopLevel_boxModel_borderColor_inactive};
    box-shadow: ${GlobalNav_link_TopLevel_boxModel_boxShadow_inactive};
    font-family: ${GlobalNav_link_TopLevel_copy_fontFamily_inactive};
    /* font-size: ${GlobalNav_link_TopLevel_copy_fontSize_inactive}px; */
    font-size: 14px;
    font-weight: ${GlobalNav_link_TopLevel_copy_fontWeight_inactive};
    color: ${brandOrange};
    letter-spacing: ${GlobalNav_link_TopLevel_copy_letterSpacing_inactive}px;
    line-height: ${GlobalNav_link_TopLevel_copy_lineHeight_inactive};

    &:hover {
      background-color: ${GlobalNav_link_TopLevel_boxModel_background_hover};
      /* padding: ${GlobalNav_link_TopLevel_boxModel_paddingVertical_hover}px ${GlobalNav_link_TopLevel_boxModel_paddingHorizontal_hover}px; */
      padding: 32px;
      border-radius: ${GlobalNav_link_TopLevel_boxModel_borderRadius_hover}px;
      border-width: ${GlobalNav_link_TopLevel_boxModel_borderWidth_hover}px;
      border-color: ${GlobalNav_link_TopLevel_boxModel_borderColor_hover};
      box-shadow: ${GlobalNav_link_TopLevel_boxModel_boxShadow_hover};
      font-family: ${GlobalNav_link_TopLevel_copy_fontFamily_hover};
      /* font-size: ${GlobalNav_link_TopLevel_copy_fontSize_hover}px; */
      font-size: 14px;
      font-weight: ${GlobalNav_link_TopLevel_copy_fontWeight_hover};
      color: ${GlobalNav_link_TopLevel_copy_fontColour_hover};
      letter-spacing: ${GlobalNav_link_TopLevel_copy_letterSpacing_hover}px;
      line-height: ${GlobalNav_link_TopLevel_copy_lineHeight_hover};
    }

    &:focus {
    }

    &:active {
      text-decoration: none;
      background-color: ${GlobalNav_link_TopLevel_boxModel_background_active};
      /* padding: ${GlobalNav_link_TopLevel_boxModel_paddingVertical_active}px ${GlobalNav_link_TopLevel_boxModel_paddingHorizontal_active}px; */
      padding: 32px;
      border-radius: ${GlobalNav_link_TopLevel_boxModel_borderRadius_active}px;
      border-width: ${GlobalNav_link_TopLevel_boxModel_borderWidth_active}px;
      border-color: ${GlobalNav_link_TopLevel_boxModel_borderColor_active};
      box-shadow: ${GlobalNav_link_TopLevel_boxModel_boxShadow_active};
      font-family: ${GlobalNav_link_TopLevel_copy_fontFamily_active};
      /* font-size: ${GlobalNav_link_TopLevel_copy_fontSize_active}px; */
      font-size: 14px;
      font-weight: ${GlobalNav_link_TopLevel_copy_fontWeight_active};
      color: ${GlobalNav_link_TopLevel_copy_fontColour_active};
      letter-spacing: ${GlobalNav_link_TopLevel_copy_letterSpacing_active}px;
      line-height: ${GlobalNav_link_TopLevel_copy_lineHeight_active};
    }

    &:disabled {
    }
  }
`

const LinkGlobalSubLevel = styled.div`
  a {
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    background-color: ${GlobalNav_link_SubLevel_boxModel_background_inactive};
    /* padding: ${GlobalNav_link_SubLevel_boxModel_paddingVertical_inactive}px ${GlobalNav_link_SubLevel_boxModel_paddingHorizontal_inactive}px; */
    padding: 16px 0 16px 0;
    border-radius: ${GlobalNav_link_SubLevel_boxModel_borderRadius_inactive}px;
    border-width: ${GlobalNav_link_SubLevel_boxModel_borderWidth_inactive}px;
    border-color: ${GlobalNav_link_SubLevel_boxModel_borderColor_inactive};
    box-shadow: ${GlobalNav_link_SubLevel_boxModel_boxShadow_inactive};
    font-family: ${GlobalNav_link_SubLevel_copy_fontFamily_inactive};
    /* font-size: ${GlobalNav_link_SubLevel_copy_fontSize_inactive}px; */
    font-size: 14px;
    font-weight: ${GlobalNav_link_SubLevel_copy_fontWeight_inactive};
    color: ${GlobalNav_link_SubLevel_copy_fontColour_inactive};
    letter-spacing: ${GlobalNav_link_SubLevel_copy_letterSpacing_inactive}px;
    line-height: ${GlobalNav_link_SubLevel_copy_lineHeight_inactive};

    &:hover {
      background-color: ${GlobalNav_link_SubLevel_boxModel_background_hover};
      /* padding: ${GlobalNav_link_SubLevel_boxModel_paddingVertical_hover}px ${GlobalNav_link_SubLevel_boxModel_paddingHorizontal_hover}px; */
      padding: 16px 0 16px 0;
      border-radius: ${GlobalNav_link_SubLevel_boxModel_borderRadius_hover}px;
      border-width: ${GlobalNav_link_SubLevel_boxModel_borderWidth_hover}px;
      border-color: ${GlobalNav_link_SubLevel_boxModel_borderColor_hover};
      box-shadow: ${GlobalNav_link_SubLevel_boxModel_boxShadow_hover};
      font-family: ${GlobalNav_link_SubLevel_copy_fontFamily_hover};
      /* font-size: ${GlobalNav_link_SubLevel_copy_fontSize_hover}px; */
      font-size: 14px;
      font-weight: ${GlobalNav_link_SubLevel_copy_fontWeight_hover};
      color: ${GlobalNav_link_SubLevel_copy_fontColour_hover};
      letter-spacing: ${GlobalNav_link_SubLevel_copy_letterSpacing_hover}px;
      line-height: ${GlobalNav_link_SubLevel_copy_lineHeight_hover};
    }

    &:focus {
    }

    &:active {
      background-color: ${GlobalNav_link_SubLevel_boxModel_background_active};
      /* padding: ${GlobalNav_link_SubLevel_boxModel_paddingVertical_active}px ${GlobalNav_link_SubLevel_boxModel_paddingHorizontal_active}px; */
      padding: 16px 0 16px 0;
      border-radius: ${GlobalNav_link_SubLevel_boxModel_borderRadius_active}px;
      border-width: ${GlobalNav_link_SubLevel_boxModel_borderWidth_active}px;
      border-color: ${GlobalNav_link_SubLevel_boxModel_borderColor_active};
      box-shadow: ${GlobalNav_link_SubLevel_boxModel_boxShadow_active};
      font-family: ${GlobalNav_link_SubLevel_copy_fontFamily_active};
      /* font-size: ${GlobalNav_link_SubLevel_copy_fontSize_active}px; */
      font-size: 14px;
      font-weight: ${GlobalNav_link_SubLevel_copy_fontWeight_active};
      color: ${GlobalNav_link_SubLevel_copy_fontColour_active};
      letter-spacing: ${GlobalNav_link_SubLevel_copy_letterSpacing_active}px;
      line-height: ${GlobalNav_link_SubLevel_copy_lineHeight_active};
    }

    &:disabled {
    }
  }
`

const LinkText = styled.a`
  a {
    color: ${brandDarkGrey};
    font-family: '${secondaryFont}';
    font-size: 23px;
    font-style: normal;
    font-weight: 200;
    line-height: 120%;
    opacity: 75%;
    text-decoration: none;

    &:hover {
      color: ${brandOrange};
      opacity: 1;
    }

    @media only screen and (min-width: 48em) {
      font-size: 39px;
    }
  }
`

const FooterLink = styled.a`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: ${brandOrange};
  }

  &:visited {
    color: ${brandDarkGrey};

    &:hover {
      color: ${brandOrange};
    }
  }
`

const LinkTextBody = styled.span`
  a {
    color: ${brandDarkGrey};
    font-family: ${primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    opacity: 75%;
    text-decoration: none;

    &:hover {
      color: ${brandOrange};
      opacity: 1;
    }

    &:visited {
      color: ${brandDarkGrey};

      &:hover {
        color: ${brandOrange};
      }
    }
  }
`

const LinkCTA = styled.a`
  align-items: center;
  background: ${brandOrange};
  border: 0;
  box-shadow: 0px 0px 0px 0px ${brandBlack};
  color: ${brandWhite};
  display: flex;
  flex-direction: column;
  font-family: ${secondaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: 120%; /* 24px */
  padding: 16px 32px 16px 16px;
  position: relative;
  text-decoration: none;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:after {
    background-color: ${brandWhite};
    bottom: -16px;
    content: '';
    height: 32px;
    position: absolute;
    right: -16px;
    transform: rotate(-45deg);
    width: 32px;
    z-index: 1;
  }

  a {
    color: ${brandWhite};
    text-decoration: none;
    transition:
      background-color 0.25s ease-in-out,
      color 0.25s ease-in-out;
  }

  &:hover {
    background: ${brandSoftOrange};

    a {
      color: ${brandOrange};
    }
  }
`

// ---------- Cut Button ----------//
const LinkCTALarge = styled.a`
  align-items: center;
  background: ${brandOrange};
  border: 0;
  box-shadow: 0px 0px 0px 0px ${brandBlack};
  color: ${brandWhite};
  display: flex;
  flex-direction: column;
  font-family: '${secondaryFont}';
  text-decoration: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: 120%; /* 24px */
  padding: 40px;
  position: relative;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:visited {
    color: ${brandWhite};
  }

  &:after {
    background-color: ${brandWhite};
    bottom: -7px;
    content: '';
    height: 24px;
    position: absolute;
    right: -15px;
    transform: rotate(-45deg);
    width: 40px;
    z-index: 1;
  }

  &:hover {
    background: ${brandSoftOrange};
    color: ${brandOrange};
  }
`

// ---------- Simple Button ----------//
const SimpleButton = css`
  display: inline-flex;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid ${brandOrange};
  box-shadow: 0px 0px 0px 0px ${brandBlack};
  color: ${brandOrange};
  text-decoration: none;

  /* typography/H5/Bold */
  font-family: '${secondaryFont}';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  transition: 0.25s ease-in-out;

  &:hover {
    color: ${brandWhite};
    background-color: ${brandOrange};

    &:visited {
      color: ${brandWhite};
    }
  }

  &:visited {
    color: ${brandOrange};
  }

  @media only screen and (min-width: 48em) {
    font-size: 20px;
  }
`

const SimpleButtonOuterLink = styled.a`
  ${SimpleButton}
`

const SimpleButtonOrange = styled(Link)`
  ${SimpleButton}
`

const KhakiButtonLink = styled(SimpleButtonOrange)`
  color: ${brandWhite};
  background-color: ${brandKhaki};
  border-color: ${brandKhaki};

  &:visited {
    color: ${brandWhite};
  }

  &:hover {
    color: ${brandWhite};
    background-color: ${brandKhaki};
    filter: opacity(80%);

    &:visited {
      color: ${brandWhite};
    }
  }
`

const SimpleButtonWhite = styled(Link)`
  display: inline-flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid ${brandOrange};
  box-shadow: 0px 0px 0px 0px ${brandBlack};
  color: ${brandOrange};
  text-decoration: none;

  &:visited {
    color: ${brandOrange};
  }

  /* typography/H5/Bold */
  font-family: '${secondaryFont}';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  transition: 0.25s ease-in-out;

  &:hover {
    color: ${brandWhite};
    background-color: ${brandOrange};
  }

  @media only screen and (min-width: 48em) {
    font-size: 20px;
  }
`

const GoRentalButton = styled.div`
  margin-top: 16px;

  a {
    cursor: pointer;
    color: var(--Brand---Orange, ${brandOrange});
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
`

const GoUpgradeButton = styled(GoRentalButton)`
  margin-top: 0;
`

export const FeatureCardButton = styled(Link)`
  align-items: flex-start;
  align-self: stretch;
  background-color: ${brandIvory};
  color: ${brandDarkGrey};
  display: flex;
  flex-direction: column;
  font-family: ${secondaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 32px 48px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:after {
    background-color: ${brandWhite};
    bottom: -7px;
    content: '';
    height: 24px;
    position: absolute;
    right: -15px;
    transform: rotate(-45deg);
    width: 40px;
    z-index: 1;
  }

  &:hover {
    background-color: ${brandOrange};
    color: ${brandWhite};
  }
`
const AsideArticleButton = styled(FeatureCardButton)`
  background-color: ${brandOrange};
  color: ${brandWhite};
  font-family: ${secondaryFont};

  &:hover {
    background-color: ${brandSoftOrange};
    color: ${brandOrange};
  }

  &:visited {
    color: ${brandWhite};
    &:hover {
      color: ${brandOrange};
    }
  }
`

// Component Export
//---------------------------------
export default function LinkAtom({ linkDestination, linkText, linkType, ...prop }) {
  return (
    <>
      {linkType === 'Text' && (
        <LinkText>
          <Link {...prop} to={linkDestination}>
            {linkText}
          </Link>
        </LinkText>
      )}

      {linkType === 'Text Body' && (
        <LinkTextBody>
          <Link {...prop} to={linkDestination}>
            {linkText}
          </Link>
        </LinkTextBody>
      )}

      {linkType === 'Primary CTA' && (
        <LinkCTA>
          <Link {...prop} to={linkDestination}>
            {linkText}
          </Link>
        </LinkCTA>
      )}
      {linkType === 'Primary Large CTA' && (
        <LinkCTALarge {...prop} href={linkDestination}>
          {linkText}
        </LinkCTALarge>
      )}
      {linkType === 'Simple Button Link' && (
        <SimpleButtonOrange {...prop} to={linkDestination}>
          {linkText}
        </SimpleButtonOrange>
      )}
      {linkType === 'Simple Button Outer Link' && (
        <SimpleButtonOuterLink {...prop} href={linkDestination}>
          {linkText}
        </SimpleButtonOuterLink>
      )}
      {linkType === 'Khaki Button Link' && (
        <KhakiButtonLink {...prop} to={linkDestination}>
          {linkText}
        </KhakiButtonLink>
      )}
      {linkType === 'White Button Link' && (
        <SimpleButtonWhite {...prop} to={linkDestination}>
          {linkText}
        </SimpleButtonWhite>
      )}
      {linkType === 'Footer Link' && (
        <FooterLink {...prop} href={linkDestination}>
          {linkText}
        </FooterLink>
      )}
      {linkType === 'Rental Button' && (
        <GoRentalButton>
          <Link to={linkDestination}>{linkText}</Link>
        </GoRentalButton>
      )}
      {linkType === 'Upgrade Button' && (
        <GoUpgradeButton>
          <Link to={linkDestination}>{linkText}</Link>
        </GoUpgradeButton>
      )}
      {linkType === 'Card Button Link' && (
        <FeatureCardButton to={linkDestination}>{linkText}</FeatureCardButton>
      )}
      {linkType === 'Aside Article Link' && (
        <AsideArticleButton as="a" href={linkDestination} {...prop}>
          {linkText}
        </AsideArticleButton>
      )}
    </>
  )
}

LinkAtom.propTypes = {
  linkDestination: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
  target: PropTypes.string,
}
