import React from 'react'

import { StoreProvider } from './StoreContext'
import { CustomerProvider } from './CustomerContext'
import { AppProvider } from './app-context'
import { ToastProvider } from './ToastContext'

function CombinedProvider({ children }) {
  return (
    <AppProvider>
      <ToastProvider>
        <CustomerProvider>
          <StoreProvider>{children}</StoreProvider>
        </CustomerProvider>
      </ToastProvider>
    </AppProvider>
  )
}

export default CombinedProvider
