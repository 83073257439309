import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import isomorphicFetch from 'isomorphic-fetch'
import { createCartMutation } from './createCartMutation'
import { cartLinesRemoveMutation } from './cartLinesRemove'
import { addCartLinesMutation } from './addCartLines'
import { cartBuyerIdentityUpdateMutation } from './cartBuyerIdentityUpdate'
import { updateCartLinesMutation } from './updateCartLines'

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: '2024-04',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  customFetchApi: isomorphicFetch,
})

async function getCart(cartId) {
  const getCartByIdQuery = `
          query {
    cart(
      id: "${cartId}"
    ) {
      id
      checkoutUrl
      createdAt
      updatedAt
      lines(first: 50) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                  featuredImage {
                    url
                    altText
                  }
                  priceRange {
                    minVariantPrice {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
            attributes {
              key
              value
            }
          }
        }
      }
      attributes {
        key
        value
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
      buyerIdentity {
        email
        phone
        customer {
          id
          email
        }
        countryCode
        deliveryAddressPreferences {
          ... on MailingAddress {
            address1
            address2
            city
            provinceCode
            countryCodeV2
            zip
          }
        }
      }
    }
  }
      `

  try {
    const response = await client.request(getCartByIdQuery)
    return response.data.cart
  } catch (error) {
    console.error(`Error creating cart: ${error}`)
  }
}

async function createCart(lines = []) {
  try {
    const response = await client.request(createCartMutation, {
      variables: {
        lines,
      },
    })

    if (response.data.cartCreate?.userErrors?.length) {
      const { cart } = response.data.cartCreate

      return {
        data: await undoCart(cart.id, [], cart?.lines?.edges),
        error: response.data.cartCreate?.userErrors[0]?.message,
      }
    }

    return {
      error: null,
      data: response.data.cartCreate.cart,
    }
  } catch (error) {
    console.error(`Error creating cart: ${error}`)
    throw error
  }
}

async function addLineItem(cartId, lines) {
  try {
    const prevCart = await getCart(cartId)

    const response = await client.request(addCartLinesMutation, {
      variables: {
        cartId,
        lines,
      },
    })

    if (response.data.cartLinesAdd?.userErrors?.length) {
      return {
        error: response.data.cartLinesAdd?.userErrors[0]?.message,
        data: await undoCart(
          cartId,
          prevCart.lines.edges,
          response.data.cartLinesAdd.cart?.lines?.edges
        ),
      }
    }

    return {
      error: null,
      data: response.data.cartLinesAdd.cart,
    }
  } catch (error) {
    console.error(`Error deleting line item: ${error}`)
    throw error
  }
}

async function removeLineItem(cartId, lineIds) {
  try {
    const response = await client.request(cartLinesRemoveMutation, {
      variables: {
        cartId,
        lineIds,
      },
    })

    if (response.data.cartLinesRemove?.userErrors?.length) {
      return {
        error: response.data.cartLinesRemove?.userErrors[0]?.message,
        data: response.data.cartLinesRemove.cart,
      }
    }

    return {
      error: null,
      data: response.data.cartLinesRemove.cart,
    }
  } catch (error) {
    console.error(`Error deleting line item: ${error}`)
    throw error
  }
}

async function updateLineItem(cartId, lines) {
  try {
    const response = await client.request(updateCartLinesMutation, {
      variables: {
        cartId,
        lines,
      },
    })

    if (response.data.cartLinesUpdate?.userErrors?.length) {
      const message = response.data.cartLinesUpdate?.userErrors[0]?.message
      return {
        error: message,
        data: response.data.cartLinesUpdate.cart,
      }
    }

    return {
      error: null,
      data: response.data.cartLinesUpdate.cart,
    }
  } catch (error) {
    console.error(`Error updating line item: ${error}`)
    throw error
  }
}

async function undoCart(cartId, prevLines, resultLines) {
  const linesToUndo = resultLines.map((line) => line.node.id)

  const removed = await removeLineItem(cartId, linesToUndo)

  if (prevLines?.length) {
    const added = await addLineItem(
      cartId,
      prevLines.map((line) => ({
        merchandiseId: line.node.merchandise.id,
        quantity: line.node.quantity,
        attributes: line.node.attributes,
      }))
    )

    return added.data
  }

  return removed.data
}

async function buyerIdentityUpdate(buyerIdentity, cartId) {
  try {
    const response = await client.request(cartBuyerIdentityUpdateMutation, {
      variables: {
        buyerIdentity,
        cartId,
      },
    })

    return response.data.cartBuyerIdentityUpdate.cart
  } catch (error) {
    console.error(`Error buyerIdentityUpdate: ${error}`)
    throw error
  }
}

export const cartClient = {
  createCart,
  getCart,
  removeLineItem,
  addLineItem,
  updateLineItem,
  buyerIdentityUpdate,
}
