import { useEffect } from 'react'

const useClickOutside = (ref, callback, excludeArray) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const { target } = event
      if (ref.current && !ref.current.contains(target)) {
        if (excludeArray && !!excludeArray.length) {
          if (!excludeArray.some((item) => item.current?.contains(target))) {
            callback()
          }
        } else {
          callback()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default useClickOutside
