import React, { createContext, useState } from 'react'

export const AppContext = createContext()

export function AppProvider({ children }) {
  const [globalNavActive, setGlobalNavActive] = useState(false)
  const [pageHasScrolled, setpageHasScrolled] = useState(false)

  const ToggleGlobalNav = () => {
    setGlobalNavActive(!globalNavActive)
  }

  const CloseAll = () => {
    setGlobalNavActive(false)
  }

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        globalNavActive,
        ToggleGlobalNav,
        pageHasScrolled,
        setpageHasScrolled,
        CloseAll,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
