import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { cartClient } from '../utils/cart/cart'
import { extractProductsFromCart } from '../helpers/extractProductsFromCart'
import { useToast } from './ToastContext'
import useCustomer from './CustomerContext'
import { v4 as uuidv4 } from 'uuid'

const defaultValues = {
  cart: null,
  cartItems: [],
  cartRentalItems: [],
  cartId: null,
  loading: false,
  setCart: () => {},
  addVariantToCart: () => {},
  addVariantsListToCart: () => {},
  removeLineItem: () => {},
  clearCart: () => {},
  removeBuyerIdentity: () => {},
  onCheckout: () => {},
}

export const RENTAL_DEPOSIT_VARIANT_ID = 'gid://shopify/ProductVariant/43830498263274'

const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== 'undefined'
const localStorageCartKey = 'shopify_cart'
const localStorageNeedToClearCartKey = 'shopify_need_to_clear_cart'
const localStorageCartItemsKey = 'shopify_cart_items'
const localStorageCartRentalItemsKey = 'shopify_cart_rental_items'

export function StoreProvider({ children }) {
  const [cart, setCart] = useState(defaultValues.cart)
  const [cartItems, setCartItems] = useState(defaultValues.cartItems)
  const [cartRentalItems, setCartRentalItems] = useState(defaultValues.cartItems)
  const [cartId, setCartId] = useState(defaultValues.cartId)
  const [loading, setLoading] = useState(false)

  const { showToast } = useToast()
  const { accessToken, customer } = useCustomer()

  const setCartIdToStorage = useCallback((cartId) => {
    if (!cartId) return
    localStorage.setItem(localStorageCartKey, cartId)
  }, [])

  // rental:
  // 2. if removed last rental product than remove deposit
  // 3. what if we have a few rentals, what's with the deposit

  const updateBuyerIdentity = useCallback(async (token, customer, cartId) => {
    const buyerIdentity = {
      customerAccessToken: token,
      email: customer.email,
      phone: customer.phone,
      countryCode: customer.countryCode || 'GB',
    }

    try {
      await cartClient.buyerIdentityUpdate(buyerIdentity, cartId)
    } catch (error) {
      console.error('Error updating buyer identity:', error)
    }
  }, [])

  const removeBuyerIdentity = useCallback(async () => {
    if (!cartId) return

    try {
      await cartClient.buyerIdentityUpdate(
        {
          customerAccessToken: null,
        },
        cartId
      )
    } catch (error) {
      console.error('Error removing buyer identity:', error)
    }
  }, [cartId])

  const onCheckout = useCallback(async () => {
    setLoading(true)
    try {
      localStorage.setItem(localStorageNeedToClearCartKey, 'true')
      // localStorage.removeItem(localStorageCartKey)

      const cart = await cartClient.getCart(cartId)

      const linesObj = (cart.lines?.edges || [])?.reduce(
        (acc, item) => {
          const node = item.node
          const units = parseInt(node?.attributes.find((attr) => attr.key === 'Units')?.value)

          if (units && !isNaN(units)) {
            const newAttributes =
              node?.attributes?.map((attr) => ({
                ...attr,
                value: attr.key === 'Units' ? '1' : attr.value,
              })) || []
            for (let i = 0; i < units; i++) {
              acc.splitLines.push({
                merchandiseId: node.merchandise.id,
                quantity: node.quantity,
                attributes: [...newAttributes, { key: '_split_unique_id', value: uuidv4() }],
              })
            }
          } else {
            acc.splitLines.push({
              merchandiseId: node.merchandise.id,
              quantity: node.quantity,
              attributes: node.attributes,
            })
          }

          acc.lines.push({
            quantity: node.quantity,
            merchandiseId: node.merchandise.id,
            attributes: node.attributes || [],
          })

          acc.ids.push(node.id)

          return acc
        },
        { lines: [], ids: [], splitLines: [] }
      )

      localStorage.setItem(localStorageCartItemsKey, JSON.stringify(linesObj.lines))

      await cartClient.removeLineItem(cartId, linesObj.ids)
      await cartClient.addLineItem(cartId, linesObj.splitLines)

      const a = document.createElement('a')
      a.href = cart?.checkoutUrl

      setLoading(false)

      a.click()
      a.remove()
    } catch (e) {
      setLoading(false)
    }
  }, [cartId])

  const getCart = useCallback(
    async (savedCartId) => {
      const cartData = await cartClient.getCart(savedCartId)
      setCart(cartData)
      setCartItems(extractProductsFromCart(cartData))
      setCartId(savedCartId)

      if (accessToken && customer) {
        updateBuyerIdentity(accessToken, customer, savedCartId)
      }
    },
    [isBrowser, accessToken, customer, updateBuyerIdentity]
  )

  const createCart = useCallback(
    async (lines = []) => {
      const cart = (await cartClient.createCart(lines))?.data
      setCart(cart)
      setCartId(cart?.id)
      setCartItems(extractProductsFromCart(cart))
      localStorage.setItem(localStorageCartKey, cart?.id)
      if (accessToken && customer) {
        updateBuyerIdentity(accessToken, customer, cart?.id)
      }
    },
    [isBrowser, accessToken, customer, updateBuyerIdentity]
  )

  const initializeCart = useCallback(
    async (lines = []) => {
      const savedCartId = isBrowser ? localStorage.getItem(localStorageCartKey) : null
      const needToClear = isBrowser ? localStorage.getItem(localStorageNeedToClearCartKey) : null

      if (savedCartId) {
        try {
          const localCart = await cartClient.getCart(savedCartId)

          if (!localCart) {
            await createCart(needToClear === 'true' ? [] : lines)
          } else {
            await getCart(savedCartId)
          }
        } catch (error) {
          console.error('Error initializing cart:', error)
        }
      } else {
        await createCart(lines)
      }

      localStorage.removeItem(localStorageNeedToClearCartKey)
    },
    [isBrowser, getCart, createCart]
  )

  useEffect(() => {
    const cartRentalItems = localStorage.getItem(localStorageCartRentalItemsKey)
    setCartRentalItems(JSON.parse(cartRentalItems || '[]'))
  }, [])

  useEffect(() => {
    const cartItems = localStorage.getItem(localStorageCartItemsKey)

    const parsedCartItems = JSON.parse(cartItems) || []

    initializeCart(parsedCartItems)
  }, [initializeCart])

  // useEffect(() => {
  //   const needToClear = localStorage.getItem(localStorageNeedToClearCartKey)

  //   if (needToClear === 'true') return

  //   initializeCart()
  // }, [initializeCart])

  const addVariantsListToCart = async (products) => {
    setLoading(true)
    const lines = products
      .map((product, index) =>
        prepareForCart(
          product.product,
          product?.quantity,
          product?.isRentalMetafield,
          product?.weeksCount,
          product?.rentalData,
          index === 0
        )
      )
      .flat()

    const totalCount = products.reduce((acc, curr) => {
      if (curr?.isRentalMetafield) {
        acc += 1
      } else {
        acc += curr.quantity
      }

      return acc
    }, 0)

    try {
      const cart = cartId
        ? await cartClient.addLineItem(cartId, lines)
        : await cartClient.createCart(lines)

      const rentalProducts = products.filter((product) => !!product?.isRentalMetafield)

      if (rentalProducts.length) {
        const rentalItems = [
          ...cartRentalItems,
          ...rentalProducts.map((product) => ({
            variantId: product.product.variants[0]?.id || product.product.variants[0]?.shopifyId,
          })),
        ]
        setCartRentalItems(rentalItems)
        localStorage.setItem(localStorageCartRentalItemsKey, JSON.stringify(rentalItems))
      }

      setCartItems(extractProductsFromCart(cart.data))
      setCartIdToStorage(cart.data.id)
      setCartId(cart.data.id)
      setCart(cart.data)

      if (cart.error) {
        showToast("Cart hasn't been updated", cart.error)
      } else {
        showToast(
          'Cart has been updated',
          `${totalCount} item${totalCount > 1 ? 's' : ''} added to your cart`
        )
      }
    } catch (error) {
      console.error(`Error in addVariantsListToCart: ${error}`)
      showToast("Cart hasn't been updated", error?.message || 'Unhandled error')
    } finally {
      setLoading(false)
    }
  }

  const prepareForCart = (
    product,
    quantity,
    isRentalMetafield,
    weeksCount = 1,
    rentalData,
    includeDeposit = false
  ) => {
    const variantId = product.variants[0]?.id || product.variants[0]?.shopifyId
    const parsedQuantity = parseInt(quantity, 10)

    const newCartItem = {
      merchandiseId: variantId,
      quantity: parsedQuantity || 1,
    }
    const rentalDepositItem = {
      merchandiseId: RENTAL_DEPOSIT_VARIANT_ID,
      quantity: 1,
    }

    const attributes = rentalData
      ? [
          {
            key: 'From',
            value: rentalData.from,
          },
          {
            key: 'To',
            value: rentalData.to,
          },
          {
            key: 'Units',
            value: rentalData.units,
          },
          ...[
            rentalData?.rrp
              ? {
                  key: '_item_value',
                  value: rentalData.rrp,
                }
              : null,
          ],
          ...[
            rentalData?.flyerId
              ? {
                  key: '_flyerID',
                  value: rentalData.flyerId,
                }
              : null,
          ],
          {
            key: '_insurance',
            value: rentalData?.insurance || 'https://placehold.co/400x400',
          },
          ...[
            rentalData?.training
              ? {
                  key: '_training_additional',
                  value: rentalData?.training || 'https://placehold.co/400x400',
                }
              : null,
          ],
          {
            key: '_deposit_accepted',
            value: 'on',
          },
          ...Object.entries(rentalData?.additionalData || {}).map(([k, v]) => ({
            key: k,
            value: v,
          })),
        ].filter(Boolean)
      : []

    if (cartId) {
      const lines = [newCartItem]

      if (isRentalMetafield) {
        lines[0] = {
          ...newCartItem,
          attributes,
        }
        includeDeposit && lines.push(rentalDepositItem)
      }

      return lines
    } else {
      const lineItemsForCart = [
        ...cartItems.map((item) => ({
          merchandiseId: item.product.id,
          quantity: item.quantity,
          attributes: item.attributes,
        })),
        newCartItem,
      ]

      const linesWithRental = [...lineItemsForCart]

      if (isRentalMetafield && includeDeposit) linesWithRental.push(rentalDepositItem)

      return linesWithRental
    }
  }

  const addVariantToCart = async (
    product,
    quantity,
    isRentalMetafield,
    weeksCount = 1,
    rentalData
  ) => {
    setLoading(true)
    const lines = prepareForCart(product, quantity, isRentalMetafield, weeksCount, rentalData)

    try {
      const variantId = lines[0].merchandiseId
      let cart = {}

      if (cartId) {
        cart = await cartClient.addLineItem(cartId, lines)
      } else {
        cart = await cartClient.createCart(lines)
      }

      if (isRentalMetafield) {
        setCartRentalItems([...cartRentalItems, { variantId }])
        localStorage.setItem(
          localStorageCartRentalItemsKey,
          JSON.stringify([...cartRentalItems, { variantId }])
        )
      }

      setCartItems(extractProductsFromCart(cart.data))
      setCartIdToStorage(cart.data?.id)
      setCartId(cart.data?.id)
      setCart(cart.data)

      if (cart.error) {
        showToast("Cart hasn't been updated", cart.error)
      } else {
        showToast('Cart has been updated', '1 item added to your cart')
      }
    } catch (error) {
      console.error(`Error in addVariantToCart: ${error}`)
      showToast("Cart hasn't been updated", error?.message || 'Unhandled error')
    } finally {
      setLoading(false)
    }
  }

  const removeLineItem = async (cartLineId) => {
    setLoading(true)

    try {
      const lines = [cartLineId]

      const depositItem = cartItems.find((item) => item.product.id === RENTAL_DEPOSIT_VARIANT_ID)

      if (cartLineId === depositItem?.product?.cartLineId && cartRentalItems.length > 1) {
        showToast('Error removing item', "You can't remove the deposit item")
        return
      }

      const cartItem = cartItems.find((item) => item.product.cartLineId === cartLineId)
      const cartItemVariantId = cartItem?.product?.id

      if (
        !!cartRentalItems.length &&
        depositItem?.product?.cartLineId &&
        cartRentalItems.find((item) => item.variantId === cartItemVariantId)
      ) {
        if (!cartItem?.attributes?.find((attribute) => attribute.key === '_parent_id')?.value) {
          if (cartRentalItems.length === 1) {
            lines.push(depositItem.product.cartLineId)
          } else {
            if (depositItem.quantity === 1) {
              lines.push(depositItem.product.cartLineId)
            } else {
              const newCartItem = {
                id: depositItem.product.cartLineId,
                merchandiseId: depositItem.product.id,
                quantity: depositItem.quantity - 1,
              }

              await cartClient.updateLineItem(cartId, [newCartItem])
            }
          }
        }
      }

      const removeResponse = await cartClient.removeLineItem(cartId, lines)

      setCartItems(extractProductsFromCart(removeResponse.data))
      setCart(removeResponse.data)

      const newRentalItems = cartRentalItems.filter((item) => item.variantId !== cartItemVariantId)
      setCartRentalItems(newRentalItems)
      localStorage.setItem(localStorageCartRentalItemsKey, JSON.stringify(newRentalItems))

      if (removeResponse.error) {
        showToast("Cart hasn't been updated", removeResponse.error)
      } else {
        showToast('Cart has been updated', '1 item removed from your cart')
      }
    } catch (error) {
      console.error(`Error in removeLineItem: ${error}`)
      showToast("Cart hasn't been updated", error?.message || 'Unhandled error')
    } finally {
      setLoading(false)
    }
  }

  const removeLineItems = async (cartLineIds, onSuccess, onError) => {
    setLoading(true)

    try {
      const lines = cartLineIds || []

      const depositItem = cartItems.find((item) => item.product.id === RENTAL_DEPOSIT_VARIANT_ID)

      if (lines.some((item) => item === depositItem?.product?.cartLineId) && cartItems.length > 1) {
        showToast('Error removing item', "You can't remove the deposit item")
        onError?.()
        return
      }

      const updateLines = []

      for (const lineId of cartLineIds) {
        const cartItem = cartItems.find((item) => item.product.cartLineId === lineId)
        const cartItemVariantId = cartItem?.product?.id

        if (
          !!cartRentalItems.length &&
          depositItem?.product?.cartLineId &&
          cartRentalItems.find((item) => item.variantId === cartItemVariantId)
        ) {
          if (cartItem?.attributes?.find((attribute) => attribute.key === '_parent_id')?.value) {
            continue
          }

          if (lines.includes(depositItem.product.cartLineId)) {
            continue
          }

          if (cartRentalItems.length === 1) {
            lines.push(depositItem.product.cartLineId)
          } else {
            if (depositItem.quantity === 1) {
              lines.push(depositItem.product.cartLineId)
            } else {
              const newCartItem = {
                id: depositItem.product.cartLineId,
                merchandiseId: depositItem.product.id,
                quantity: depositItem.quantity - 1,
              }

              updateLines.push(newCartItem)
            }
          }
        }
      }

      if (updateLines.length) {
        await cartClient.updateLineItem(cartId, updateLines)
      }

      const removeResponse = await cartClient.removeLineItem(cartId, lines)

      setCartItems(extractProductsFromCart(removeResponse.data))
      setCart(removeResponse.data)

      const cartItemVariantIds = cartItems
        .filter((item) => cartLineIds.includes(item.product.cartLineId))
        .map((item) => item.product.id)

      const newRentalItems = cartRentalItems.filter(
        (item) => !cartItemVariantIds.includes(item.variantId)
      )
      setCartRentalItems(newRentalItems)
      localStorage.setItem(localStorageCartRentalItemsKey, JSON.stringify(newRentalItems))

      if (removeResponse.error) {
        showToast("Cart hasn't been updated", removeResponse.error)
        onError?.()
      } else {
        onSuccess?.()
        showToast(
          'Cart has been updated',
          `${cartLineIds?.length || 1} item removed from your cart`
        )
      }
    } catch (error) {
      showToast("Cart hasn't been updated", error?.message || 'Unhandled error')
      onError?.()
      console.error(`Error in removeLineItem: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const updateLineItem = async (newLineItems, onError) => {
    setLoading(true)
    try {
      const updatedResponse = await cartClient.updateLineItem(cartId, newLineItems)

      setCartItems(extractProductsFromCart(updatedResponse.data))
      setCart(updatedResponse.data)

      if (updatedResponse.error) {
        showToast("Cart hasn't been updated", updatedResponse.error)
        onError?.()
      } else {
        showToast('Cart has been updated', '')
      }
    } catch (error) {
      showToast("Cart hasn't been updated", error?.message || 'Unhandled error')
      console.error(`Error in updateLineItem: ${error}`)
      onError?.()
    } finally {
      setLoading(false)
    }
  }

  const clearCart = useCallback(() => {
    if (!customer?.email) return

    setCart(defaultValues.cart)
    setCartId(defaultValues.cartId)
    setCartItems(defaultValues.cartItems)
    localStorage.removeItem(localStorageCartKey)
  }, [customer])

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addVariantsListToCart,
        removeLineItem,
        removeLineItems,
        updateLineItem,
        clearCart,
        cart,
        setCart,
        cartId,
        cartItems,
        onCheckout,
        lineItemsCount: cartItems.length,
        loading,
        removeBuyerIdentity,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useStore = () => {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error('useStore must be used within StoreContext')
  }

  return context
}

export default useStore
