export const customerAddressesQuery = `
query customerQuery($input: String!) {
  customer(customerAccessToken: $input) {
    addresses (first: 10) {
        edges {
            node {
                address1
                address2
                city
                company
                country
                name
                id
                zip
                firstName
                lastName
                phone
                province
            }
        }
    }
  }
}
    `
