export const extractProductsFromCart = (cart) => {
  const products = cart?.lines?.edges?.map(({ node }) => {
    const { product } = node.merchandise
    const variant = node.merchandise

    return {
      product: {
        id: variant.id,
        cartLineId: node.id,
        title: variant.title === 'Default Title' ? product.title : variant.title,
        image: product.featuredImage ? product.featuredImage.url : null,
        price: variant.price.amount,
        currency: variant.price.currencyCode,
      },
      quantity: node.quantity,
      attributes: node.attributes,
    }
  })

  return products || []
}
