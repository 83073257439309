export const customerUpdateMutation = `
mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
  customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
    customer {
    id
    firstName
    lastName
    acceptsMarketing
    email
    phone
    defaultAddress {
        id
        city
    }
    }
    customerAccessToken {
      accessToken
    }
    customerUserErrors {
      code
      field
      message
    }
    userErrors {
      field
      message
    }
  }
}
    `
