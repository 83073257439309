export default (url) => {
  if (!url) return '#'

  const firstElemByColon = url.split(':')[0]

  if (firstElemByColon === 'https' || firstElemByColon === 'http') return url

  if (url.includes('.')) return `https://${url}`

  return url[0] === '/' || url === '#' ? url : `/${url}`
}
