import React from 'react'
import styled from 'styled-components'
import Loader from '../../atoms/loader/Loader'
import TypographyAtom from '../../atoms/typography/typography'

const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  padding: 50px 0;
`

function SkeletonElement({ placeholder = 'Loading...' }) {
  return (
    <Skeleton>
      <Loader />
      <TypographyAtom copy={placeholder} type="P" weight="Regular" />
    </Skeleton>
  )
}

export default SkeletonElement
