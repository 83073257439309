import { ROUTES } from '../constants/routes'

export const getSearchLink = (contentType, slug) => {
  switch (contentType) {
    case 'product':
      return `${ROUTES.PRODUCTS}/${slug}`
    case 'pageBlogArticle':
      return `${ROUTES.BLOG}/posts/${slug}`
    case 'pageHomapage':
      return '/'
    case 'pageTopLevel':
      return `${ROUTES.SERVICES}/${slug}`
    case 'pageSubLevelTemplate':
      return `${ROUTES.SERVICES}/${slug}`
    case 'pageCollections':
      return `${ROUTES.COLLECTIONS}/${slug}`
    case 'pageTrainingTopLevel':
      return ROUTES.TRAINING_TOP_LEVEL
    case 'pageProductFamily':
      return `${ROUTES.COLLECTIONS}/${slug}`
    case 'pageSurveyTopLevel':
      return ROUTES.SURVEY_TOP_LEVEL
    case 'pageRichSubPage':
      return `${ROUTES.SERVICES}/${slug}`
    case 'pageBasicPage':
      return `${ROUTES.SUPPORT}/${slug}`
    case 'pageGsdCalculator':
      return `/${slug}`
    case 'pageIndustry':
      return ROUTES.INDUSTRY_TOP_LEVEL
    case 'pageIndustrySubLevel':
      return `${ROUTES.INDUSTRY_TOP_LEVEL}/${slug}`
    case 'pageTrainingCalculator':
      return `/${slug}`
    case 'pageJob':
      return `${ROUTES.CAREERS}/${slug}`
    case 'pageRepairCalculator':
      return `/${slug}`
    default:
      return '/'
  }
}
