export const customerActivateMutation = `
  mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
    customerActivate(id: $id, input: $input) {
      customer {
        id
        firstName
        lastName
        acceptsMarketing
        email
        phone
        defaultAddress {
          id
          city
        }
      }
      customerAccessToken {
        accessToken
      }
      customerUserErrors {
        code
        field
        message
      }
      userErrors {
        field
        message
      }
    }
  }
`
