export const customerDefaultAddressUpdateMutation = `
mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
  customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
    customer {
    id
    firstName
    lastName
    acceptsMarketing
    email
    phone
    defaultAddress {
        id
    }
    }
    customerUserErrors {
      code
      field
      message
    }
    userErrors {
      field
      message
    }
  }
}
    `
