import React, { createContext, useState, useContext } from 'react'

const defaultValues = {
  showAdditionalMenu: false,
  setShowAdditionalMenu: () => {},
}

const AppWrappedContext = createContext(defaultValues)

export const useAppWrapped = () => useContext(AppWrappedContext)

export function AppWrappedProvider({ children }) {
  const [showAdditionalMenu, setShowAdditionalMenu] = useState(false)

  return (
    <AppWrappedContext.Provider value={{ showAdditionalMenu, setShowAdditionalMenu }}>
      {children}
    </AppWrappedContext.Provider>
  )
}
