import * as React from 'react'
import styled from 'styled-components'
import { useAppWrapped } from '../../context/AppWrappedContext'

const Layout = styled.main`
  @media screen and (min-width: 64em) {
    ${(props) => props.adjusted && 'padding-top: 50px;'}
  }
`

export default function DefaultLayout({ children }) {
  const AppWrappedContext = useAppWrapped()
  return <Layout adjusted={AppWrappedContext.showAdditionalMenu}>{children}</Layout>
}
